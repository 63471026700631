<template>
  <div class="pages">
    <div class="page">
      <div class="inside-nav">
        <el-tree
          :data="treeData"
          :props="defaultProps"
          :highlight-current="true"
          accordion
          @node-click="handleNodeClick"
        >
        </el-tree>
      </div>

      <div class="task_box">
        <!-- 顶部按钮 -->
        <div class="radiobtn" v-if="children">
          <el-radio-group
            v-model="tabPosition"
            style="margin-bottom: 30px"
            @change="tabhandleClick(tabPosition)"
          >
            <el-radio-button label="1">区域用电</el-radio-button>
            <el-radio-button label="2">
              <el-select
                v-model="label"
                placeholder="请选择设"
                @change="changeTime"
              >
                <el-option
                  v-for="item in sheType"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-radio-button>
          </el-radio-group>
        </div>
        <!-- select 筛选 -->
        <div class="select_box">
          <div class="s_item">
            <div class="s_lable">数据周期：</div>
            <el-radio-group v-model="from.radio">
              <el-radio :label="1">昨日</el-radio>
              <el-radio :label="2">上周</el-radio>
              <el-radio :label="3">上月</el-radio>
              <el-radio :label="4">上季度</el-radio>
              <el-radio :label="5">上年</el-radio>
              <el-radio :label="6">自定义</el-radio>
            </el-radio-group>
          </div>

          <div class="btns" @click="submits">查询</div>
        </div>
        <div class="oper_box" v-if="from.radio == '6'">
          <div class="s_item s_item_s">
            <div class="s_lable">开始日期：</div>
            <!-- <el-date-picker v-model="from.start" type="date" @change="starttimeChange(start)" placeholder="选择日期">
            </el-date-picker> -->
            <el-date-picker
              v-model="from.start"
              type="date"
              @change="starttimeChange"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
          <div class="s_item s_item_s">
            <div class="s_lable">截止日期：</div>
            <!-- <el-date-picker v-model="from.end" type="date" @change="endtimeChange(end)" placeholder="选择日期">
            </el-date-picker> -->
            <el-date-picker
              v-model="from.end"
              type="date"
              @change="endtimeChange"
              placeholder="选择日期"
            >
            </el-date-picker>
          </div>
        </div>
        <div class="charts">
          <div class="onecharts" v-if="!children"></div>
          <div class="twocharts" v-if="children"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      treeData: [],
      defaultProps: {
        children: "children",
        label: "title",
      },
      time: "",
      activeIndex: "1",
      indexs: 1,
      dynamicTags: ["标签一", "标签二", "标签三"],
      from: {
        organize_id: "",
        start: "",
        end: "",
        date: "",
        radio: 1,
        date: "",
      },
      radioType: 1,
      children: false,
      sheType: [],
      tabPosition: "1",
      label: "",
      region: 1,
    };
  },
  mounted: function () {
    // this.qiCharts();
    this.getzuzhiList();
  },
  methods: {
    // 周期下拉
    changeTime(e) {
      this.device_type = e;
    },
    // tab 切换
    tabhandleClick(tab) {
      console.log(tab, "ttttttt");
      if (tab == 1) {
        this.region = 1;
        this.sheType = [];
      } else {
        this.region = 0;
        this.getsheType();
      }
    },
    // 设备类型
    async getsheType() {
      // if(this.tabPosition == 1){
      const res = await this.$http.post("other/device_type");
      this.sheType = res.data;
      const type = this.sheType.map((option) => {
        return {
          value: option.value,
          label: option.text,
        };
      });
      this.sheType = type;
      console.log(this.sheType, "this.sheType ");
      // }
    },
    // 1组织 获取
    async getzuzhiList() {
      const res = await this.$http.post("Organize");
      this.treeData = res.data;
    },
    handleNodeClick(data) {
      // 组织
      console.log(data);
      this.from.organize_id = data.id;
      if (!data.children) {
        this.children = true;
      } else {
        this.children = false;
      }
    },
    async submits() {
      if (this.from.radio == 1) {
        this.from.date = "yesterday";
      } else if (this.from.radio == 2) {
        this.from.date = "lastweek";
      } else if (this.from.radio == 3) {
        this.from.date = "lastmonth";
      } else if (this.from.radio == 4) {
        this.from.date = "lastquarter";
      } else if (this.from.radio == 5) {
        this.from.date = "lastyear";
      } else if (this.from.radio == 6) {
        this.from.date = "custom";
      }
      if (this.children == false) {
        console.log(111);
        const res = await this.$http.post("device/electric_ranking", this.from);
        this.oneChart(res.data);
      } else {
        const res = await this.$http.post("device/electric_ranking", {
          organize_id: this.from.organize_id,
          start: this.from.start,
          end: this.from.end,
          date: this.from.date,
          region: this.region,
          device_type: this.device_type,
        });
        this.twoChart(res.data);
      }
    },

    // 时间选择
    starttimeChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.from.start = newDate;
    },
    endtimeChange(val) {
      const newDate =
        val.getFullYear() + "-" + (val.getMonth() + 1) + "-" + val.getDate();
      this.from.end = newDate;
    },

    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },

    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    qiCharts() {
      this.$nextTick(() => {
        this.oneChart();
        this.twoChart();
      });
    },
    twoChart(data) {
      //   var series=[];    //定义一个数组变量用于存放配置

      // for(var i = 0;i<data.params.length;i++){
      //     series.push({
      //         name: data.params[i].name,
      //         type: 'bar',
      //         stack: '总量',
      //           barWidth: 40,
      //         data: data.params[i].list
      //     });
      // }
      var myChart = echarts.init(document.querySelector(".twocharts"));
      var twoOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          crossStyle: {
            color: "#999",
          },
        },
        legend: {
          // data:[],
          textStyle: {
            color: "#fff", // 文本颜色
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            color: "#fff", // 文本颜色
          },
        },
        yAxis: {
          type: "category",
          data: data.name,
          axisLabel: {
            color: "#fff", // 文本颜色
          },
        },
        series: {
          name: data.name,
          type: "bar",
          tooltip: {
            valueFormatter: function (value) {
              return value + " kW/h";
            },
          },
          data: data.params,
          color: ["#33C3D6"],
          barWidth: "30%",
        },
      };
      myChart.setOption(twoOption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
    oneChart(data) {
      var series = []; //定义一个数组变量用于存放配置

      for (var i = 0; i < data.params.length; i++) {
        series.push({
          name: data.params[i].name,
          type: "bar",
          stack: "总量",
          barWidth: 40,
          data: data.params[i].list,
        });
      }
      console.log(series, "fff");
      var myChart = echarts.init(document.querySelector(".onecharts"));
      var oneOption = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          crossStyle: {
            color: "#999",
          },
        },
        legend: {
          // data:[],
          textStyle: {
            color: "#fff", // 文本颜色
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "value",
          boundaryGap: [0, 0.01],
          axisLabel: {
            color: "#fff", // 文本颜色
          },
        },
        yAxis: {
          type: "category",
          data: data.name,
          axisLabel: {
            color: "#fff", // 文本颜色
          },
        },
        series: series,
      };
      myChart.setOption(oneOption);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep
  .el-tree--highlight-current
  .el-tree-node.is-current
  > .el-tree-node__content {
  background: #68d8fe;
  color: #fff;
}
.pages {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}
.page {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

// 副菜单
.inside-nav {
  width: 200px;
  height: calc(100% - 40px);
  margin-top: 20px;
  box-sizing: border-box;
  border: #61cdf1 2px solid;
  background-color: #1b284d;
  overflow-y: auto;
}
/* 左侧二级菜单滚动条 设置滚动条 */
.inside-nav::-webkit-scrollbar {
  width: 4px;
}
.inside-nav::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #68d8fe;
}
.inside-nav::-webkit-scrollbar-track {
  border-radius: 0;
  background: #1b284d;
}
/* 设置滚动条  end */

.el-tree {
  background-color: #1b284d;
  color: #ffffff;
}
::v-deep .el-tree-node__label {
  font-size: 14px;
}
::v-deep .el-tree-node__content {
  height: 30px;
}
::v-deep .el-tree-node:focus > .el-tree-node__content {
  background-color: #1b284d;
  color: #68d8fe;
}
::v-deep .el-tree-node__content:hover,
::v-deep .el-upload-list__item:hover {
  background-color: #1b284d;
  color: #68d8fe;
}

// 表格
.task_box {
  width: calc(100% - 220px);
  ::v-deep .el-input__inner {
    font-family: Adobe Heiti Std;
    font-weight: normal;
    color: #e5e5e5 !important;
    border: none;
    background: rgba(0, 0, 0, 0) !important;
    border: 1px solid #61cdf1 !important;
  }
  // charts
  .onecharts {
    width: 100%;
    height: 600px;
    margin-top: 100px;
  }
  .twocharts {
    width: 100%;
    height: 600px;
    margin-top: 100px;
  }
  // 顶部按钮
  .elmenu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .el-menu.el-menu--horizontal {
      border: 1px solid #61cdf1 !important;
    }
    .el-menu.el-menu--horizontal {
      border: none !important;
    }
    .el-menu {
      background-color: rgba(0, 0, 0, 0) !important;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .el-menu--horizontal > .el-menu-item {
      color: #fff !important;
    }
    .el-menu-item.is-active {
      color: #68d8fe !important;
    }

    .el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
    .el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
    .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
      background-color: rgba(0, 0, 0, 0) !important;
    }
    .el-menu-item {
      border-left: 0px;
      margin-right: 20px;
    }
  }
  .oper_box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 15px;
    margin-bottom: 20px;

    .btns {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #ffffff;
      margin-left: 25px;
      background-color: #68d8fe;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      padding: 0 12px;
      cursor: pointer;
      .el-icon {
        font-size: 20px;
        margin-right: 5px;
      }
    }
    .s_item {
      display: flex;
      align-items: center;
      margin-left: 80px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
  }

  // select 筛选
  .index_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-tag {
      margin-right: 10px;
    }
  }
  .select_box {
    display: flex;
    align-items: center;
    padding: 15px;
    .s_item {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .s_lable {
        font-size: 16px;
        color: #ffffff;
      }
    }
    .btns {
      height: 40px;
      line-height: 40px;
      text-align: center;
      background-color: #68d8fe;
      font-size: 16px;
      color: #ffffff;
      width: 80px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 20px;
    }
  }
}
.s_item_s {
  margin-right: 30px;
  margin-left: 0px !important;
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-radius: 4px !important;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
  border-radius: 4px !important;
}
::v-deep .el-radio-button__inner {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #fff;
  padding: 12px;
  background: rgba(0, 0, 0, 0);
  border: 1px solid #68d8fe;
  border-radius: 4px;
  margin-left: 30px;
}
.radiobtn {
  padding-left: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  ::v-deep .el-input__inner {
    border: none !important;
    padding: 0 !important;
  }
}
</style>
